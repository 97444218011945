import { Partner } from '@shared/types/enums';
import aegeanFonts from './aegean';
import airFranceFonts from './airfrance';
import airTransatFonts from './airtransat';
import aviancaFonts from './avianca';
import dohopconnectFonts from './dohopconnect';
import easyjetFonts from './easyjet';
import hawaiianairlinesFonts from './hawaiianairlines';
import jazeeraFonts from './jazeera';
import jetstarFonts from './jetstar';
import laCompagnieFonts from './lacompagnie';
import norseFonts from './norse';
import oneworldFonts from './oneworld';
import philippineairlinesFonts from './philippineairlines';
import tigerairtwFonts from './tigerairtwfonts';
import transaviaFonts from './transavia';
import vietJetAirFonts from './vietjetair';
import voloteaFonts from './volotea';
import vuelingFonts from './vueling';

export const getFonts = (partner: Partner) => {
  switch (partner) {
    case Partner.Easyjet:
      return easyjetFonts;
    case Partner.Dohop:
      return dohopconnectFonts;
    case Partner.Vueling:
      return vuelingFonts;
    case Partner.Volotea:
      return voloteaFonts;
    case Partner.Avianca:
      return aviancaFonts;
    case Partner.PhilippineAirlines:
      return philippineairlinesFonts;
    case Partner.AirTransat:
      return airTransatFonts;
    case Partner.AirFrance:
      return airFranceFonts;
    case Partner.Transavia:
      return transaviaFonts;
    case Partner.Norse:
      return norseFonts;
    case Partner.Oneworld:
      return oneworldFonts;
    case Partner.Jazeera:
      return jazeeraFonts;
    case Partner.VietJetAir:
      return vietJetAirFonts;
    case Partner.Aegean:
      return aegeanFonts;
    case Partner.LaCompagnie:
      return laCompagnieFonts;
    case Partner.TigerairTaiwan:
      return tigerairtwFonts;
    case Partner.HawaiianAirlines:
      return hawaiianairlinesFonts;
    case Partner.Jetstar:
      return jetstarFonts;
    default:
      return dohopconnectFonts;
  }
};

export const getPreloadLinks = (partner: Partner) => {
  switch (partner) {
    case Partner.Easyjet:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/easyjet-rounded-book.woff2"
          key="easyjet-rounded-book"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/easyjet-generation-headline.woff2"
          key="easyjet-generation-headline"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/easyjet_rounded_demi-webfont.woff2"
          key="easyjet-rounded-demi"
          rel="preload"
        />,
      ];
    case Partner.Dohop:
    case Partner.DohopConnect:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/DohopConnect/TTInterfaces-Light.ttf"
          key="Interfaces"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/DohopConnect/TTInterfaces-Regular.ttf"
          key="Interfaces"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/DohopConnect/TTInterfaces-Medium.ttf"
          key="Interfaces"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/DohopConnect/TTInterfaces-SemiBold.ttf"
          key="Interfaces"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/DohopConnect/TTInterfaces-Bold.ttf"
          key="Interfaces"
          rel="preload"
        />,
      ];
    case Partner.Vueling:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/vueling/NunitoSans-Light.woff2"
          key="NunitoSansLight"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/vueling/NunitoSans-Regular.woff2"
          key="NunitoSans"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/vueling/NunitoSans-semiBold.woff2"
          key="NunitoSansSemiBold"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/vueling/NunitoSans-Bold.woff2"
          key="NunitoSansBold"
          rel="preload"
        />,
      ];
    case Partner.Avianca:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Avianca/RedHatDisplay-VariableFont.woff2"
          key="RedHatDisplay"
          rel="preload"
        />,
      ];
    case Partner.Volotea:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Volotea/arial.ttf"
          key="volotea-arial"
          rel="preload"
        />,
      ];
    case Partner.PhilippineAirlines:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/philippineairlines/Montserrat-Regular.woff2"
          key="Montserrat"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/philippineairlines/Montserrat-Bold.woff2"
          key="Montserrat-Bold"
          rel="preload"
        />,
      ];
    case Partner.AirTransat:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/AirTransat/ApercuPro-Regular.woff2"
          key="ApercuPro-Regular"
          rel="preload"
        />,
      ];
    case Partner.Norse:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Norse/Inter-Regular.woff2"
          key="Inter"
          rel="preload"
        />,
      ];
    case Partner.Jazeera:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Jazeera/29LT_Bukra_Regular.woff2"
          key="ltbukra"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Jazeera/29LT_Bukra_Semi_Bold.woff2"
          key="ltbukra-semibold"
          rel="preload"
        />,
      ];
    case Partner.AirFrance:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/AirFrance/Typography_Excellence_In_Motion_Medium_V5.woff2"
          key="ExcellenceInMotion-Medium"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/AirFrance/Typography_ExcellenceInMotion_Regular.woff2"
          key="ExcellenceInMotion-Regular"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/AirFrance/Typography_ExcellenceInMotion_Bold.woff2"
          key="ExcellenceInMotion-Bold"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/AirFrance/Typography_ExcellenceInMotion_Thin.woff2"
          key="ExcellenceInMotion-Thin"
          rel="preload"
        />,
      ];
    case Partner.Transavia:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Transavia/Nexa-Book.woff2"
          key="NexaBook"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Transavia/Nexa-ExtraBold.woff2"
          key="NexaExtraBold"
          rel="preload"
        />,
      ];
    case Partner.VietJetAir:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/VietJetAir/KoHo-Regular.ttf"
          key="KohoRegular"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/VietJetAir/KoHo-Medium.ttf"
          key="KohoMedium"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/VietJetAir/KoHo-SemiBold.ttf"
          key="KohoSemiBold"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/VietJetAir/KoHo-Bold.ttf"
          key="KohoBold"
          rel="preload"
        />,
      ];
    case Partner.Aegean:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Aegean/URWGeometricExt-Regular.woff2"
          key="URWGeometricExt-Regular"
          rel="preload"
        />,
      ];
    case Partner.LaCompagnie:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/LaCompagnie/RobotoCondensed-Bold.ttf"
          key="RobotoCondensed"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/LaCompagnie/TitilliumWeb-Light.woff2"
          key="TitilliumWeb"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/LaCompagnie/TitilliumWeb-Bold.woff2"
          key="TitilliumWebBold"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/LaCompagnie/TitilliumWeb-SemiBold.woff2"
          key="TitilliumWebSemiBold"
          rel="preload"
        />,
      ];
    case Partner.Oneworld:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Oneworld/Roboto-Light.woff2"
          key="Roboto"
          rel="preload"
        />,
      ];
    case Partner.TigerairTaiwan:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/tigerairtw/MuseoSansRounded-500.woff2"
          key="MuseoSansRounded"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/tigerairtw/MuseoSansRounded-900.woff2"
          key="MuseoSansRounded-Bold"
          rel="preload"
        />,
      ];
    case Partner.HawaiianAirlines:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/hawaiianairlines/slateProRegular.woff2"
          key="slateProRegular"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/hawaiianairlines/slateProLight.woff2"
          key="slateProLight"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/hawaiianairlines/slateProMedium.woff2"
          key="slateProMedium"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/hawaiianairlines/slateProBold.woff2"
          key="slateProBold"
          rel="preload"
        />,
      ];
    case Partner.Jetstar:
      return [
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Jetstar/JetstarHappyRegular.woff2"
          key="JetstarHappyRegular"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Jetstar/JetstarHappyBold.woff2"
          key="JetstarHappyBold"
          rel="preload"
        />,
        <link
          as="font"
          crossOrigin="anonymous"
          href="/fonts/Jetstar/helveticaneue.woff2"
          key="JetstarHelveticaNeue"
          rel="preload"
        />,
      ];
    default:
      return null;
  }
};
