import type { NextRequest } from 'next/server';
import {
  RequestCookie,
  RequestCookies,
} from 'next/dist/compiled/@edge-runtime/cookies';
import { GetPartnerExperimentQuery } from '@codegen/cmsUtils';
import { Partner } from '@shared/types/enums';
import { parseKeyValueCookieToObject } from '@utils/cookieUtils';
import { isOnClient } from '@utils/helperUtils';
import { Route } from '@web/types/enums';
import { Experiment } from '@web/types/experimentTypes';
import { EdgeConfig } from './middlewareUtils';

export const experimentPrefix = 'experiment.';

export const getPartnerExperiments = ({
  experiments,
  partner,
}: {
  experiments?: Maybe<EdgeConfig['experiments']>;
  partner: Partner;
}) => {
  if (!experiments) {
    return null;
  }

  return Object.keys(experiments).reduce<Maybe<EdgeConfig['experiments']>>(
    (acc, experimentName) => {
      const experiment = experiments[experimentName];

      if (!experiment?.partners.includes(partner)) {
        return acc;
      }

      return {
        ...acc,
        [experimentName]: experiment,
      };
    },
    null,
  );
};

export const generateExperimentCookieValues = ({
  cookies,
  experiments,
  isPrefetch,
  pathName,
}: {
  cookies: RequestCookies;
  experiments?: EdgeConfig['experiments'] | null;
  isPrefetch?: boolean;
  pathName: string;
}) => {
  let setCookies: { name: string; value: string }[] = [];

  if (!experiments || isPrefetch) {
    return setCookies;
  }

  Object.entries(experiments).forEach(([experimentName, experiment]) => {
    const cookieName = `${experimentPrefix}${experimentName}`;
    const currentCookie = cookies.get(cookieName);

    if (
      currentCookie ||
      experiment.ratio < Math.random() ||
      (experiment.routes && !experiment.routes.includes(pathName))
    ) {
      return;
    }

    const variantRatioSum = Object.values(experiment.variants).reduce<number>(
      (acc, variant) => acc + variant.ratio,
      0,
    );

    // If the sum of the variant ratios is greater than 1,
    // we don't want to set cookies for that experiment
    if (variantRatioSum > 1) {
      return;
    }

    const variantNames = Object.keys(experiment.variants);

    const cumulativeRatios = variantNames.reduce<number[]>((acc, name) => {
      const ratio = experiment.variants[name]?.ratio;

      if (typeof ratio === 'number') {
        return [...acc, (acc[acc.length - 1] || 0) + ratio];
      }

      return acc;
    }, []);

    const random = Math.random();

    for (let i = 0; i < cumulativeRatios.length; i++) {
      const ratio = cumulativeRatios[i];
      const value = variantNames[i];

      if (ratio && value && random < ratio) {
        setCookies = [
          ...setCookies,
          {
            name: cookieName,
            value,
          },
        ];

        return;
      }
    }
  });

  return setCookies;
};

export const getRewriteKey = ({
  experimentCookies,
  experiments,
  pathName,
  req,
}: {
  experimentCookies: ReturnType<typeof generateExperimentCookieValues>;
  experiments?: EdgeConfig['experiments'] | null;
  pathName: string;
  req: NextRequest;
}) => {
  if (!experiments) {
    return {
      rewriteRoute: null,
      experimentRoute: null,
    };
  }
  // We want to pick the first found experiment in case there are more experiments
  // with the same rewrite route
  const experiment = Object.entries(experiments).find(([name, config]) => {
    const variantKeys = Object.keys(config.variants);
    const currentCookie = req.cookies.get(`${experimentPrefix}${name}`);

    return (
      config.route === pathName &&
      (experimentCookies.some((c) => variantKeys.includes(c.value)) ||
        currentCookie)
    );
  });

  const variants = experiment?.[1]?.variants || {};
  const experimentName = experiment?.[0];
  const experimentRoute = experiment?.[1]?.route;

  const variantKey = Object.keys(variants).find((variantName) => {
    const key = variants[variantName]?.rewrite;

    return (
      key &&
      (experimentCookies.some((cookie) => cookie.value === variantName) ||
        req.cookies.get(`${experimentPrefix}${experimentName}`)?.value ===
          variantName)
    );
  });

  return {
    rewriteRoute: variantKey ? variants[variantKey]?.rewrite : null,
    experimentRoute,
  };
};

export const getExperimentCookiesToDelete = ({
  experimentCookies,
  experiments,
}: {
  experimentCookies: RequestCookie[];
  experiments?: Maybe<EdgeConfig['experiments']>;
}) => {
  if (!experiments) {
    return experimentCookies;
  }

  return experimentCookies.filter((cookie) => {
    const experimentName = cookie.name.replace(experimentPrefix, '');

    if (!Object.keys(experiments).includes(experimentName)) {
      return true;
    }

    return experiments[experimentName]?.ratio === 0;
  });
};

export const getExperimentKeys = (serverCookie?: string) => {
  const experimentsFromCookies = parseKeyValueCookieToObject(
    isOnClient() ? document.cookie : serverCookie,
    ';',
  );

  return Object.keys(experimentsFromCookies)
    .filter((cookie) => cookie.startsWith(experimentPrefix))
    .map((cookie) => ({
      name: cookie,
      variant: experimentsFromCookies[cookie] ?? '',
    }));
};

export const getExperimentDataFromCMS = ({
  experiments,
  partnerExperiment,
}: {
  experiments: Experiment[];
  partnerExperiment?: GetPartnerExperimentQuery['partner'];
}) => {
  return partnerExperiment?.experiments
    .find((cmsExperiment) =>
      experiments.find(
        (experiment) =>
          experiment.name ===
          `${experimentPrefix}${cmsExperiment.experimentId}`,
      ),
    )
    ?.variants.find((cmsVariant) =>
      experiments.find(
        (experiment) => experiment.variant === cmsVariant.variant,
      ),
    );
};

export const getExperimentsKeysForRoutes = (route: Route) => {
  switch (route) {
    case Route.PassengerFares:
      return [
        {
          name: 'experiment.2023q4-move-pax-step-v5',
          variant: 'variantMovePaxStep',
        },
      ];
    case Route.Fares:
      return [
        {
          name: 'experiment.2023q4-move-pax-step-v5',
          variant: 'variantControl',
        },
      ];
    default:
      return [];
  }
};

export const getCookieExpirationInAYear = () => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  return oneYearFromNow.toUTCString();
};
